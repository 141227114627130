<template>
  <div class="edit-profile">
    <Load v-if="loadingStatus" />
    <v-alert
      v-if="toast"
      color="success"
      variant="tonal"
      text
      style="margin: 5px;"
    >
      <div style="font-weight: 800;  color: red;">
        {{ textToast }}
      </div>
    </v-alert>

    <v-alert
      v-if="toastPrompts"
      color="success"
      variant="tonal"
      text
      style="font-weight: 800; margin: 5px;"
    >
      Users who have added at least 3 <a
        style="text-decoration: underline;"
        @click="openPrompts()"
      >prompts</a>  get more matches
    </v-alert>
      
    <div class="edit-profile__form">
      <BaseLabel
        label="Photos"
        class="edit-profile__label"
      />
      <div
        style="margin-top: 5px; margin-bottom: 5px; width: 100%;"
      >
        <form
          class="form"
          action="#"
          method="post"
        >
          <div
            class="fv-row"
          >
            <div
              id="kt_dropzonejs_example_1"
              class="dropzone"
            >
              <div
                class="dz-message needsclick"
                style="display: flex; justify-content: center; align-items: center;"
              >
                <i
                  style="color: #009ef7; font-size: 30px;"
                  class="bi bi-file-earmark-arrow-up"
                />
                <div class="ms-4">
                  <h3 class="fs-5 fw-bold text-gray-900 mb-1">
                    Drop pictures here or click to upload.
                  </h3>
                  <span class="fs-7 fw-semibold text-gray-400">10 pics max.</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <BaseError
        v-if="error"
        :message="errorText"
      />
      <div style="text-align: center;"> 
        <BaseButton
          class="btn"
          text="Edit Prompts"
          @my-event="openPrompts()"
        />
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import {
    BaseError,
    BaseLabel,
    BaseButton,
    Load
} from '@/components/Element';
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
export default {
    name: 'Profile',
    components: {
      BaseError,
      BaseLabel,
      BaseButton,
      Load,
    },
    data() {
      return {
        error: null,
        files: [],
        filesUpload:[],
        images: [],
        isDragging: false,
        errorText: '',
        dialogPhoto: false,
        userPhotos: [],
        deletedPhotos: [],
        text: '',
        firstNameModel: '',
        lastNameModel: '',
        ageModel: '',
        genderModel: '',
        heightFeetModel: '',
        heightInchesModel: '',
        searchForModel: '',
        zipCodeModel: '',
        toast: false,
        toastPrompts: false,
        textToast: 'Nobody can see your profile until you add a picture!'
      };
    },
    computed: {
      ...mapGetters(['photos', 'bio', 'isAboutMeValid', 'user', 'loadingStatus'])
    },
    watch: {
      files() {
        let filteredPhoto = this.userPhotos.filter(item => item);
        let filteredFile = this.files.filter(item => item);
        if (filteredPhoto.length + filteredFile.length >= 3) {
          this.toast = false;
          this.error = false;
          this.errorText = '';
          if(!this.user.prompts || this.user.prompts.length <= 0){
            this.textToast = 'Nobody can see your profile until you add a picture!'
            this.toastPrompts = true;
          }else{
            this.toastPrompts = false;
          }

        } else {
          this.error = true;
          this.toast = true;
          this.toastPrompts = false;
          this.errorText = '3 photos will get you more matches';
        }

        if(this.user.photos.length > 0 && this.user.photos.length < 3){
          this.textToast = 'Users who upload at least 3 photos get 3.6 times more matches'
        }
      },
      user: {
        immediate: false,
        handler() {
          this.text = this.user.bio;
          this.filesUpload = [...this.user.photos];
          this.files = [...this.user.photos];
          this.firstNameModel = this.user.firstName;
          this.lastNameModel = this.user.lastName;
          this.ageModel = this.user.age + '';
          this.genderModel = this.user.gender;
          this.heightFeetModel = this.user.feetHeight + '';
          this.heightInchesModel = this.user.inchesHeight + '';
          this.searchForModel = this.user.searchFor;
          this.zipCodeModel = this.user.zipCode + '';
        }
      }
    },
    created(){
      this.init();
    },
    mounted(){
      var myDropzone = new Dropzone('#kt_dropzonejs_example_1', {
        url: 'https://api.tryfilters.com/users/upphoto', // Set the url for your upload script location
        paramName: 'file', // The name that will be used to transfer the file
        maxFiles: 10,
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        thumbnailWidth: 160,
        thumbnailHeight: 160,
        headers: {
          'Authorization':'Bearer ' + this.user.jwtToken,
        }
      });

      myDropzone.on('removedfile',(deleted) => {
        if(deleted.name !== ''){
          this.remove(deleted.name);
        }
      });

      myDropzone.on('success', (file, response) => {
        if(response && response?.id){
          this.setUser(response);
        }
      });

      this.filesUpload.forEach((item, key) => {
        let mockFile = { name: item, size: 123450 };
        myDropzone.displayExistingFile(mockFile, `https://osp.tryfilters.com/${item}`,null,'anonymous',true)
      })

    },
    methods: {
      ...mapActions(['updateUser','uploadPhotos', 'delPhotos']),
      ...mapMutations(['addPhoto', 'addDeletedPhoto', 'setUser']),
      async submited() {
        this.files = this.files.filter(item => item !== undefined);
        this.addPhoto(this.files);
        this.addDeletedPhoto(this.deletedPhotos);
        const response = await this.updateUser({
          bio: this.text,
          firstName: this.firstNameModel,
          lastName: this.lastNameModel,
          age: parseInt(this.ageModel),
          gender: this.genderModel,
          feetHeight: parseInt(this.heightFeetModel),
          inchesHeight: parseInt(this.heightInchesModel),
          searchFor: this.searchForModel,
          zipCode: parseInt(this.zipCodeModel)
        });
        if(!response.valid){
          console.log(response.message)
          alert(response.message)
        }
        this.init();
      },
      async openPrompts(){
        this.$router.push({
          name: 'prompts'
        });
      },
      init(){
        console.log(this.user.photos.length);
        if(!this.user.photos || this.user.photos.length <= 0){
          this.toast = true;
          setTimeout(() => {
            this.dialogPhoto = true;
          }, 30000);
        } else {
          if(this.user.photos.length > 0 && this.user.photos.length < 3){
            this.textToast = 'Users who upload at least 3 photos get 3.6 times more matches'
          }
          if(this.user.photos.length >= 3){
            this.toast = false;
            if(!this.user.prompts || this.user.prompts.length <= 0){
              this.textToast = 'Nobody can see your profile until you add a picture!'
              this.toastPrompts = true;
            }else{
              this.toastPrompts = false;
            }
          }else{
            this.toast = true;
          }
          this.user.photos.forEach(e => {
            this.filesUpload.push(e);
          });
          this.files = [...this.user.photos];
        }
      },
      async remove(i) {
        try {
          const response = await this.delPhotos(i);
          if(response.valid){
            this.filesUpload = [...response.data];

            if(this.user.photos.length > 0 && this.user.photos.length < 3){
              this.textToast = 'Users who upload at least 3 photos get 3.6 times more matches'
            }
            if(!this.user.prompts || this.user.prompts.length <= 0){
              this.textToast = 'Nobody can see your profile until you add a picture!'
            }

          }else{
            console.log(response);
          }
        } catch (error) {
          console.log(error);
          return;
        }
      },
      generateURL(file) {
        let fileSrc = URL.createObjectURL(file);
        setTimeout(() => {
          URL.revokeObjectURL(fileSrc);
        }, 1000);
        return fileSrc;
      },
      removeDuplicates(arr) {
        return arr.filter((item,index) => arr.indexOf(item) === index);
      },
      async onChange(e) {
        this.files = [];
        const fileList = e.target.files;
        if(fileList.length <= 0){
          return;
        }
        try {
          let datafiles = [...fileList];
          datafiles.forEach(element =>{
            this.files.push(element);
          })
          datafiles = [];
          this.files = this.removeDuplicates(this.files);
          const response = await this.uploadPhotos(this.files);
          this.files = [];
          if(response && response.valid){
            this.filesUpload = [...response.data];
          }else{
            if(response.message){
              alert('NO IMAGE');
            }
          }
        } catch (error) {
          console.log(error);
          alert('ERROR');
          return;
        }
      },
      dragover(e) {
        e.preventDefault();
        this.isDragging = true;
      },
      dragleave() {
        this.isDragging = false;
      },
      drop(e) {
        e.preventDefault();
        this.$refs.file.files = e.dataTransfer.files;
        this.onChange({
          target: {
            files: e.dataTransfer.files
          }
        });
        this.isDragging = false;
      },
      changePage(){
        this.dialogPhoto = false;
      },
    }
  };
</script>
  
<style lang="scss" scoped>
  .dropzone-container {
    width: 100%;
    padding: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;
    background: #ffffff;
    border: 1px solid #009933;
    border-radius: 8px;

    @media (max-width: 500px) {
    }

    .hidden-input {
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
    }

    .file-label {
      text-align: center;
      font-size: 15px;
      display: block;
      cursor: pointer;
    }

    .preview-container {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      flex-wrap: wrap;

      .preview-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #ffffff;
        padding: 5px;
        margin-left: 5px;

        .preview-img {
          width: 115px;
          height: 140px;
          object-fit: contain;
          border-radius: 5px;
          border: 1px solid #a2a2a2;
          background-color: #ffffff00;
        }

        .btntrash{
          color: red !important;
          font-size: 20px;
        }
      }

    }
  }

  .dropzone{
    width: 100%;
    border-radius: 8px;
    border-width: 2px;
    border-style: dashed;
    border-color: #009ef7;
    text-align: center;

    .dz-thumbnail img { background: red; }
  }

  .dropzone::ng-deep .dz-preview .dz-complete .dz-image-preview .dz-image img{
    height: 250px !important;
  }

  .dz-remove
{
    display:inline-block !important;
    width:1.2em;
    height:1.2em;
    
    position:absolute;
    top:5px;
    right:5px;
    z-index:1000;
    
    font-size:1.2em !important;
    line-height:1em;
   
    text-align:center;
    font-weight:bold;
    border:1px solid gray !important;
    border-radius:1.2em;
    color:gray;
    background-color:white;
    opacity:.5;
    
}

.dz-remove:hover
{
    text-decoration:none !important;
    opacity:1;
}



    
    .edit-profile { 
      @media (min-width: 800px) {
        margin-right: 23%;
        margin-left: 23%;
      }

      
  
      .btn{
        margin-top: 10px;
        width: 200px;
        font-size: 20px;
        padding: 10px;
        font-weight: 600;
      }
  
    &__title {
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.07em;
      text-align: center;
      margin: 30px 0;
  
      @media (max-width: 500px) {
        margin-top: 34px;
      }
    }
  
    &__form {
      padding: 0 20px;
      max-width: 800px;
      margin: auto;
    }
  
    &__photos {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    &__label {
      margin-top: 1rem;
  
      @media (max-width: 500px) {
        font-size: 22px;
      }
    }
  
    &__subLabel {
      text-align: center;
    }
  
    &__about {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  
    &__two {
      display: flex;
      justify-content: space-between;
      width: 100%;
  
      .base-input {
        width: calc(50% - 10px);
      }
    }
  
    &__boxes {
      display: grid;
      grid-template-columns: repeat(3, 229px);
      grid-auto-rows: 229px;
      grid-gap: 20px;
      margin-bottom: 1rem;
  
      @media (min-width: 800px) {
        grid-template-columns: repeat(3, 220px);
        grid-auto-rows: 220px;
        grid-gap: 20px;
        margin-bottom: 3rem;
      }
  
      @media (max-width: 760px) {
        grid-template-columns: repeat(2, 229px);
      }
  
      @media (max-width: 500px) {
        grid-template-columns: repeat(2, 150px);
        grid-auto-rows: 150px;
      }
    }
    &__btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }
    }

    .th{
  font-size: 1.75rem !important;
  font-weight: 700;
  text-align: center;
}
.base_button {
  align-self: center;
  padding: 12px 28px;
  width: 60%;
  //height: 62px;
  background: #009933;
  border: 1.5px solid #009933;
  border-radius: 8px;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  outline-width: 0;
  cursor: pointer;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    //width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
  
