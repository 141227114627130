import axios from 'axios';
import store from '@/store';

// const API_HOST =
//   process.env.VUE_APP_ENV === 'production'
//     ? 'https://tryfilters.com:3000'
//     : 'https://localhost:5001';

//TODO: Change this into env files
const API_HOST = 'https://api.tryfilters.com';
//const API_HOST = 'https://localhost:5001';
//const API_HOST = 'https://devapi.tryfilters.com';  

const instance = axios.create({
  baseURL: API_HOST,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
    config => {
      const token = store.getters['user'].jwtToken;
      if (token && token !== '') {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        delete instance.defaults.headers.common.Authorization;
      }
      return config;
    },
    error => Promise.reject(error)
);
  
instance.interceptors.response.use(undefined, async err => {
    const error = err.response;
    // if error is 401
    if (error.status === 401 && error.data.message === 'Invalid token')
      window.location.href = '/login';
    if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
      // request for a new token
      const refreshToken = await store.dispatch('refreshToken');
      if (refreshToken) {
        // update the error config with new token
        error.config.__isRetryRequest = true;
        return instance(error.config);
      } else {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error)
});
  
export default instance;
