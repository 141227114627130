<!-- eslint-disable no-unused-vars -->
<template>
  <v-app>
    <top-bar v-if="isShow" />
    <router-view />
    <v-dialog
      v-model="dialogPhoto"
      persistent
      width="auto"
    >
      <v-card>
        <v-card-title
          class="thbtn"
        >
          Add A Photo So Others Can See Your Profile
        </v-card-title>
        <v-card-text />
        <br>
        <v-card-actions style="display: flex; flex-direction: row; justify-content: center;">
          <button
            class="base_button"
            @click="changePage()"
          >
            Ok
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUser"
      persistent
      width="auto"
    >
      <v-card>
        <v-card-title
          class="thbtn"
        >
          Create a real account.
        </v-card-title>
        <v-card-text />
        <br>
        <v-card-actions style="display: flex; flex-direction: row; justify-content: center;">
          <button
            class="base_button"
            @click="changePageLogin()"
          >
            Ok
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogNewUser"
      persistent
      width="auto"
    >
      <v-card>
        <v-card-title
          class="thbtn"
        >
          Finish Account.
        </v-card-title>
        <v-card-text />
        <br>
        <v-card-actions style="display: flex; flex-direction: row; justify-content: center;">
          <button
            class="base_button"
            @click="changePageLogin()"
          >
            Ok
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { CometChat  } from '@cometchat-pro/chat';
import { useRoute } from 'vue-router'
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'App',
  data(){
    return{ 
      dialogPhoto: false,
      dialogUser: false,
      dialogNewUser: false,
      dialog: false,
      dialogU: false,
    }
  },
  computed:{
    ...mapGetters(['user']),
    isShow() {
      const route = useRoute()
      let name = route.name;
      if (name == 'Lander' || name == 'home' || name == 'login' || name == 'signup' || name == 'likes' || name == '404' || name == 'resetPassword' || name == 'forgotPassword' || name == 'prompts'  || name == 'signups' ) {
        return false;
      } else {
        return true;
      }
    },
    routerState() {
      const route = useRoute()
      let name = route.name;
      return name;
    }
  },
  watch: {
    user(val, oldVal) {
      if(this.user.temporal){
        this.dialogOpenUser()
      }else{
        this.dialogOpen();
      }
    },
    $route (to, from){
      let nameRoute = to.fullPath;
      if(nameRoute !== '/edit-profile'){
        if(this.user.temporal){
          this.dialogOpenUser()
        }else{
          this.dialogOpen();
        }
      }
    }
  },
  created() {
    this.initializeComet();
  },
  methods: {
    ...mapActions(['logoutTry']),
    initializeComet() {
      const APP_ID = '207577c306fbca5b';
      const REGION = 'us';
      const appSetting = new CometChat.AppSettingsBuilder()
        .subscribePresenceForFriends()
        .setRegion(REGION)
        .build();
      CometChat.init(APP_ID, appSetting).then(
        () => {},
        error => {
          console.log(error);
        }
      );
    },
    dialogOpen(){
      if(this.user && this.user.id !== null){
        if(!this.user.photos || this.user.photos.length < 1){
          if(!this.dialog ){
            this.dialog = true;
            console.log('dialog register');
            setTimeout(() => {
              if(this.$route.fullPath !== '/edit-profile'){
                if(this.user && this.user.id !== null){
                  if(!this.user.temporal || this.user.temporal === false){
                    this.dialogPhoto = true;
                  }
                }
              }
            }, 60000);
          }
        }
      }
    },
    dialogOpenUser(){
      if(this.user && this.user.id !== null){
        if(!this.dialogU ){
            this.dialogU = true;
            console.log('dialog register');
            setTimeout(() => {
              if(this.user && this.user.id !== null){
                if(this.user.temporal){
                  this.dialogUser = true;
                }
              }
            }, 30000);
        }
      }
    },
    changePage(){
      this.$router.push({
        name: 'editProfile'
      });
      this.dialogPhoto = false;
    },
    async changePageLogin(){
      const res = await this.logoutTry();
      this.$router.push({
        name: 'signup'
      });
      this.dialogUser = false;
    },
  }
};
</script>

<style lang="scss">
@import url('https://fonts.cdnfonts.com/css/montserrat');
* {
  font-family: Montserrat, sans-serif;
  letter-spacing: 0;
  box-sizing: border-box;
}

body {
  margin: 0;

  &.home {
    background-color: #000;
  }

.thbtn{
  //font-size: 1.75rem !important;
  white-space: normal;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}
.base_button {
  align-self: center;
  padding: 12px 28px;
  width: 60%;
  //height: 62px;
  background: #009933;
  border: 1.5px solid #009933;
  border-radius: 8px;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  outline-width: 0;
  cursor: pointer;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    //width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
}
}

@media (max-width: 600px) {
  html, body {
    overflow-x:hidden;
  }
}

input {
  font-size: 18px;
}

</style>
