<template>
  <component :is="componentName" />
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  Questions,
  AboutMe,
  Bio,
  Photos,
  FinishAccount,
  Success
} from '@/components/SignUp';
import { questions } from '@/data/questions';

export default {
  name: 'Signup',
  components: {
    Questions,
    AboutMe,
    Bio,
    Photos,
    FinishAccount,
    Success
  },
  computed: {
    ...mapGetters(['currentStep']),
    componentName() {
      let aryComponents = [
        'Questions',
        'AboutMe',
        'FinishAccount',
      ];
      let curType = this.currentStep - questions.length + 1;
      if (curType < 0) curType = 0;
      return aryComponents[curType];
    }
  },
  created() {
    this.clearPrompt();
  },
  methods: {
    ...mapMutations(['clearPrompt']),
  }
};
</script>
