<template>
  <div class="a">
    <div class="head">
      <v-alert
        v-if="toast"
        color="success"
        variant="tonal"
        text
        style="font-weight: 800; margin: 5px; font-size: 16px;"
        @click="changePage()"
      >
        Users who upload at least 3 photos get 3.6 times more matches
      </v-alert>

      <v-alert
        v-if="toastPrompts"
        color="success"
        variant="tonal"
        text
        style="font-weight: 800; margin: 5px; font-size: 16px;"
      >
        Users who have added at least 3 <a
          style="text-decoration: underline;"
          @click="openPrompts()"
        >prompts</a>  get more matches
      </v-alert>
    </div>
    
    <div
      v-if="usersLike"
      class="head"
    > 
      <p
        class="th"
      >
        New likes
      </p>
      <v-row style="display:flex; align-items: center;">
        <v-avatar
          v-if="usersLike"
          class="av"
          size="100"
          @click="viewLike(usersLike.userId)"
        >
          <v-img
            class="bg-white"
            width="300"
            :aspect-ratio="1"
            :src="`https://osp.tryfilters.com/${usersLike.photo}`"
            cover
          />
        </v-avatar>
        <p
          class="font-weight-bold text-decoration-underline th"
          style="color:green; margin-left:15px; cursor: pointer"
          @click="seeAll()"
        >
          See All
        </p>
      </v-row>
    </div>
  
    <div class="content">
      <Load v-if="state" />
      <p
        class="th"
        style="margin-left: 5px;"
      >
        Matches
      </p>
      <div v-if="users.length > 0">
        <div
          v-for="user in users"
          :key="user.uid"
          class="item"
          @click="viewChat(user.uid)"
        >
          <v-avatar
            class="avt"
            size="110"
          >
            <v-img
              class="bg-white"
              width="300"
              :aspect-ratio="1"
              :src="user.avatar"
              cover
            />
          </v-avatar>
          <p class="font-weight-medium text-truncate th">
            {{ user.name }}
          </p>
          <v-spacer />
          <v-icon
            large
            color="black darken-2"
          >
            mdi-arrow-right
          </v-icon>
        </div>
      </div>
      <div
        v-if="users.length <= 0"
        class="alert"
      >
        <v-alert
          class="th"
        >
          None yet <br> Normally it takes a few days
        </v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { CometChat } from '@cometchat-pro/chat';
import { mapActions, mapGetters } from 'vuex';
import { Load } from '@/components/Element';
export default {
  components: {
    Load
  },
  data() {
    return {
      users: [],
      state:true,
      toast: false,
      toastPrompts: false,
      dialogPhoto: false,
      textToast: 'Users who upload at least 3 photos get 3.6 times more matches'
    };
  },
  computed:{
    ...mapGetters(['user', 'usersSelections', 'likesUser']),
    usersLike(){
      if(this.user.userSelection != null){
        return this.likesUser[0];
      }
      return null;
    },
    verify(){
      if(this.likesUser != null) return true;
      return false;
    }
  },
  created(){
    this.getListLikesUser(this.user.id);
    this.init();
  },
  methods:{
    ...mapActions(['getListLikesUser', 'getUserLike']),
    seeAll(){
      this.$router.push({
        name: 'likes', 
      })
    },
    async init(){
      CometChat.getUser(this.user.id).then(() => {
        let usersRequest = new CometChat.UsersRequestBuilder().friendsOnly(true)
          .setLimit(30).build();
        usersRequest.fetchNext().then((users) => {
          this.users = users
          this.state = false
        }).catch((error) => {
          this.state = false
        });
      }).catch(err => {
        this.state = false
      });

      if(!this.user.photos || this.user.photos.length <= 0){
        this.toast = true;
        setTimeout(() => {
          this.dialogPhoto = true;
        }, 30000);
      } else {
        if(this.user.photos.length >= 3){
          this.toast = false;
          if(!this.user.prompts || this.user.prompts.length <= 0){
            this.textToast = 'Users who have added at least 3 prompts get more matches'
            this.toastPrompts = true;
          }else{
            this.toastPrompts = false;
          }
        }else{
          this.toast = true;
        }
      }
      
    },
    async viewLike(id){
      await this.getUserLike(id);
      this.$router.push({
            name: 'viewLike',
      })
    },
    viewChat(id){
      this.$router.push({ path: '/chats/'+id })
    },
    changePage(){
      this.$router.push({
        name: 'editProfile'
      });
      this.dialogPhoto = false;
    },
    openPrompts(){
      this.$router.push({
        name: 'prompts'
      });
      this.dialogPhoto = false;
    },
  }
};
</script>

<style lang="scss" scoped>
.a{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;

  @media(min-width: 700px) {
    padding-right: 22%;
    padding-left: 22%;
  }

}

  .head{
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 15px;

    @media(min-width: 700px) {
      font-size: 30px;
    }

  }

  .th{
    font-size:20px;
    font-weight: bold;
    margin-bottom: 10px;

    @media(min-width: 600px) {
      font-size: 24px;
    }
  }

  .av{
    border: 4px solid green;
    margin-left: 14px;
    cursor:pointer;

    @media(min-width: 600px) {
      border: 5px solid green;
    }
  }

  .content{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 15px;
  }

  .item{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .avt{
    margin-right: 10px;
  }

  .alert{
    padding: 10px;
    //margin-top: 5px;
  }


.th2{
  font-size: 1.75rem !important;
  font-weight: 700;
  text-align: center;
}
.base_button {
  align-self: center;
  padding: 12px 28px;
  width: 60%;
  //height: 62px;
  background: #009933;
  border: 1.5px solid #009933;
  border-radius: 8px;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  outline-width: 0;
  cursor: pointer;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    //width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>