<template>
  <div v-if="thenUsers">
    <div class="head">
      <v-spacer />
      <p class="txt">
        {{ thenUsers.length }} Likes
      </p>
      <v-spacer />
      <v-icon
        class="icon"
        large
        color="black"
        @click="next"
      >
        mdi-close
      </v-icon>
    </div>
    <div
      v-for="(user, index) in thenUsers"
      :key="index"
      class="wrapper"
    >
      <div
        class="one"
        @click="setVieUser(user.userId)"
      >
        <v-img
          class="bg-white"
          style="height: 250px; object-fit: none;"
          :aspect-ratio="1"
          :src="`https://osp.tryfilters.com/${user.photo}`"
        />
        <a class="name">
          {{ user.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Likes',
  computed:{
    ...mapGetters(['user', 'usersSelections', 'likesUser']),
    thenUsers(){
      if(this.likesUser.length > 0){
        return this.likesUser;
      }
      return null;
    }
  },
  created(){
    this.getListLikesUser(this.user.id)
    this.init()
  },
  methods:{
    ...mapActions(['getUserLike', 'getListLikesUser']),
    next(){
      this.$router.push({
        name: 'chat',
      })
    },
    init(){
    },
    async setVieUser(id){
      await this.getUserLike(id);
      this.$router.push({
        name: 'viewLike',
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.head{
    width:100%; 
    display:flex; 
    justify-content:center; 
    align-items: center;
    flex-direction: row;
    padding-top:15px; 
    background:rgb(245, 245, 245);

    .txt{
        color:black;
        font-weight: 700;
        font-size: 18px;
        //align-self: flex-start;

    }

    .icon{
        margin-right: 5px;
        padding-bottom: 15px;
    }
}
.wrapper {
    display: grid;
    justify-content: center;
    width: 100%;
    padding: 10px;
    grid-template-columns: repeat( auto-fit, minmax(200px, 1fr));
    grid-gap: 25px;

    @media(min-width: 600px) {
        padding-left: 30px;
        grid-template-columns: repeat( 3, 250px );
    }
}

.wrapper > * {
  background-color: #ffffff;
}

.one{
    cursor:pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .name{
        text-align: center;
        color:black;
        font-weight: bold;
        font-size: 15px;
    }


}
</style>