<template>
  <div class="signup-desc">
    <div class="hed">
      <v-btn
        v-if="currentStep > 0"
        color="primary"
        icon="mdi-chevron-left"
        dark
        class="btnHead"
        @click="back()"
      />
      <BaseHeader
        :title="currentQuestion.title"
      />
    </div>
    <BaseLabel
      :label="currentQuestion.prefix"
      class="signup-desc__label"
    />
    <div class="signup-desc__options">
      <div class="pl">
        <v-progress-linear
          v-model="value"
          height="20"
          color="light-green-darken-4"
          striped
          class="progress"
        >
          <strong>{{ Math.ceil(value) }}%</strong>
        </v-progress-linear>   
      </div>
       
      <BaseOption
        v-for="(option, index) in currentQuestion.options"
        :key="option"
        :text="option"
        @selected="next(index)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { questions } from '@/data/questions';
import { 
  BaseHeader,
  BaseLabel,
  BaseOption,
} from '@/components/Element';
export default {
  name: 'Question',
  components: {
    BaseHeader,
    BaseLabel,
    BaseOption,
  },
  data() {
    return {
      value: 0
    };
  },
  computed: {
    ...mapGetters(['currentStep']),
    currentQuestion() {
      return questions[this.currentStep];
    },
  },
  methods: {
    ...mapMutations(['setCurrentStep', 'setAnswer']),
    next(answerNumber) {
      let newAnswer = {
        question_id: this.currentStep + 1,
        answer_number: answerNumber, 
      };
      this.setAnswer(newAnswer)
      this.value = this.value + 10;
      this.setCurrentStep(this.currentStep + 1);
    },
    back() {
      let newAnswer = {
        question_id: this.currentStep - 1,
        answer_number: this.currentStep, 
      };
      this.value = this.value - 10;
      this.setAnswer(newAnswer)
      this.setCurrentStep(this.currentStep - 1);
    }
  }
}
</script>

<style lang="scss" scoped>
  .signup-desc {

    padding-bottom: 10px;
    
    &__label {
      margin: 20px 0 10px;

      @media (max-width: 600px) {
        margin: 1px 0 10px;
        font-size: 20px;
        //padding: 0 20px;
      }
    }

    .hed{
      //background: red; 
      display: flex; 
      flex-direction: row;
      justify-content: center;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .btnHead{
        margin-top: 20px; 
        margin-left: 0px;
        margin-right: 6 0px;

        @media (max-width: 600px) {
          margin-top: 20px; 
          margin-left: 20px;
          width: 35px;
          height: 35px;
        }
      }
    }

    &__options {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .pl{
      width: 100%;
    }
    .progress{
      //width: 50%;
      margin-bottom: 20px;
      align-self: center;
    }

    .v-progress-linear{
      width: 80%;
      margin-bottom: 20px;
      align-self: center;
    }
  }
</style>